import { useQuery } from '@apollo/client'
import { PollingInterval, usePollQueryWhileMounted } from 'graphql/data/util'
import gql from 'graphql-tag'
import { useMemo } from 'react'
import { useHttpLaunchpadListData } from 'state/http/hooks'
/*
      "discription": {
                    "token_key": "0xbcaf9c2841193182f1792d2ce370e83b16dddf050fc661c615cca4481b93e2b9",
                    "description": "my token",
                    "image_url": "https://image.roguex.io/0x0000000000000000000000000000000000000000.svg",
                    "twitter": "a33",
                    "telegram": "gewd",
                    "website": "dw"
                }
*/
const query = gql`
  query TokenList {
    statuses {
      totalSwapUser
    }
    tokens(where: { launchPoolInfo_not: null },orderBy:createdAtTimestamp,orderDirection:desc) {
      userCount
      address
      name
      symbol
      holder  (orderBy:balance,orderDirection:desc){
        userAddress
        balance
        balance_formatted
        ratio
      }
      metadata @client 
      tokenKey
      createdAtTimestamp
      createdOrigin
      launchPoolInfo {
        pool {
          id
          token0 {
            address
            symbol
          }
          token1 {
            address
            symbol
          }
        }
        isToken0
      }
    }
  }
`

export const useLaunchpadQuery = () => {
  const { data, loading, error } = usePollQueryWhileMounted(useQuery(query), PollingInterval.LightningMcQueen)
  return useMemo(() => ({ data: data?.tokens, loading, error }), [data, error, loading])
}

export const useLaunchpadDataByToken = (token?: string) => {
  const { data } = useHttpLaunchpadListData()
  if (!data || !token) return
  return data.filter((x: { address: string }) => x.address.toLocaleLowerCase() == token.toLocaleLowerCase())
}

const querySwapHistory = gql`
  query swaps($poolAddress: String!) {
    swaps(where: { pool: $poolAddress }, orderBy: timestamp, orderDirection: desc) {
      id
      transaction
      timestamp
      sender
      recipient
      token0 {
        id
        symbol
      }
      type
      token1 {
        id
        symbol
      }
      amount0
      amount1
    }
  }
`

export const useSwapHistoryQuery = (poolAddress?: string) => {
  const { data, loading, error } = usePollQueryWhileMounted(
    useQuery(querySwapHistory, {
      variables: {
        poolAddress: poolAddress?.toLocaleLowerCase(),
      },
    }),
    PollingInterval.LightningMcQueen
  )

  return useMemo(() => ({ data: data?.swaps, loading, error }), [data, error, loading])
}
