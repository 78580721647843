import styled from 'styled-components/macro'

import { ReactComponent as CloseIcon } from '../../assets/imgs/close.svg'
import { ReactComponent as DiscordIcon } from '../../assets/imgs/discord.svg'
import { ReactComponent as DocIcon } from '../../assets/imgs/doc.svg'
import { ReactComponent as DropDownIcon } from '../../assets/imgs/down.svg'
import { ReactComponent as ExchangeIcon } from '../../assets/imgs/exchange.svg'
import { ReactComponent as InfoIcon } from '../../assets/imgs/marketCap/info.svg'
import { ReactComponent as MarketIcon } from '../../assets/imgs/marketCap/market.svg'
import { ReactComponent as SettingIcon2 } from '../../assets/imgs/setting2.svg'
import { ReactComponent as StarIcon } from '../../assets/imgs/star.svg'
import { ReactComponent as TelegramIcon } from '../../assets/imgs/telegram.svg'
import { ReactComponent as TipsDarkIcon } from '../../assets/imgs/tips_dark.svg'
import { ReactComponent as TipsLightIcon } from '../../assets/imgs/tips_light.svg'
import { ReactComponent as TwitterIcon } from '../../assets/imgs/twitter.svg'
import { ReactComponent as DelIcon } from '../../assets/svg/close.svg'
import { ReactComponent as ExternalIcon } from '../../assets/svg/external.svg'

export const StyledExternalIcon = styled(ExternalIcon)`
  path {
    fill: ${({ theme }) => theme.textSecondary};
  }
`

export const StyledInfoIcon = styled(InfoIcon)`
  rect {
    fill: ${({ theme }) => theme.backgroundInteractive};
  }
`

export const StyledMarketIcon = styled(MarketIcon)`
  rect {
    fill: ${({ theme }) => theme.backgroundInteractive};
  }
`

export const StyledDocIcon = styled(DocIcon)`
  path {
    fill: ${({ theme }) => theme.textSecondary};
  }
`

export const StyledDiscordIcon = styled(DiscordIcon)`
  path {
    fill: ${({ theme }) => theme.textSecondary};
  }
`

export const StyledTelegramIcon = styled(TelegramIcon)`
  path {
    fill: ${({ theme }) => theme.textSecondary};
  }
`

export const StyledTwitterIcon = styled(TwitterIcon)`
  path {
    fill: ${({ theme }) => theme.textSecondary};
  }
`

export const StyledDropDown = styled(DropDownIcon)<{ selected?: boolean }>`
  path {
    fill: ${({ theme }) => theme.textSecondary};
  }
`

export const StyledExchange = styled(ExchangeIcon)`
  cursor: pointer;
  path {
    stroke: ${({ theme }) => theme.textSecondary};
  }
`

export const StyledSetting = styled(SettingIcon2)`
  path {
    fill: ${({ theme }) => theme.connectBtnBackground};
  }
`

export const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  path {
    fill: ${({ theme }) => theme.closeIconBg};
  }
`

export const StyledDelIcon = styled(DelIcon)`
  cursor: pointer;
  path {
    fill: ${({ theme }) => theme.textSecondary};
  }
`

export const StyledTipsDarkIcon = styled(TipsDarkIcon)`
  cursor: pointer;
  &:hover {
    circle {
      fill: rgba(0, 178, 255, 0.2);
    }
  }
`

export const StyledTipsLightIcon = styled(TipsLightIcon)`
  cursor: pointer;
  &:hover {
    circle {
      fill: rgba(0, 178, 255, 0.2);
    }
  }
`

export const StyledStarIcon = styled(StarIcon)`
  cursor: pointer;
  path {
    fill: ${({ theme }) => theme.textTertiary};
  }
`
